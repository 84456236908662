"use client";

import React, { useCallback, useEffect, useRef } from "react";
import { ProductCardSSRContent } from "@/components/ProductCard/innerContent";
import {
  getTagularSubject,
  hyphenateForTagular,
  setCorrelationId,
} from "@/utils/tagular/helpers";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";
import { cn } from "@/lib/utils";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  FormatSubType,
  FormatType,
  NYI_NUMBER,
  NYI_STRING,
} from "@/constants/tagular/main";
import {
  TagularVariant,
  translateVariant,
} from "@/utils/tagular/dataTranslationMatrices";
import { ProductClicked } from "@/hooks/eventing/types/redventures.ecommerce.v1.ProductClicked";
import useProductEvents from "@/hooks/eventing/useProductEvents";
import { useInView } from "react-intersection-observer";
import { ProductCardProps } from "@/components/ProductCard/types";
import { isElementHidden } from "@/utils/isElementHidden";
import { bindProductCardClickedEvent } from "@/lib/segment";

export function ClientSideProductCard({ onClick, ...props }: ProductCardProps) {
  const {
    partnerName,
    name,
    href,
    location,
    tagular_variant,
    subjects,
    index = -1,
    formatSubtype = FormatSubType.Grid,
    skipViewEvent = false,
    queryId,
  } = props;

  const tagularProduct: ProductClicked["product"] = {
    brand: hyphenateForTagular(
      Array.isArray(partnerName) ? partnerName[0] : (partnerName ?? ""),
    ),
    formatSubtype: formatSubtype,
    formatType: FormatType.ProductCard,
    location: location || NYI_STRING,
    name: name,
    variant: tagular_variant || translateVariant(props.type),
    position: index < 0 ? NYI_NUMBER : index + 1,
    category: getTagularSubject(subjects),
  };

  const { productClicked, productViewed, correlationId } = useProductEvents({
    product: tagularProduct,
  });

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setCorrelationId(correlationId);

    productClicked();

    onClick?.(e);
  };

  const ref = useRef<HTMLAnchorElement>();

  const {
    ref: inViewRef,
    inView,
    entry,
  } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  const setRefs = useCallback(
    (node: HTMLAnchorElement) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  useEffect(() => {
    if (!inView) {
      return;
    }

    bindProductCardClickedEvent({
      domElement: ref.current as Element,
      link: href,
      label: props.name,
      objectId: props.objectId,
      queryId: props?.queryId,
      index: props.resultsIndex,
      productSource: props.productSource,
      product: props.product,
      productCategory: props.type ?? "",
      resultLevel: props.resultLevel,
      uuid: props.uuid,
      title: props.parentTitle,
      activeRunKey: props.activeRunKey,
      position: (props.index ?? 0) + 1,
    });

    if (
      !skipViewEvent &&
      entry &&
      !isElementHidden(entry.target as HTMLElement)
    ) {
      productViewed();
    }
  }, [inView]);

  // Set URL query params only if there is a query for Segment eventing
  let hrefWithQueryParams;

  if (!!queryId) {
    const url = new URL(href || "/", window.location.origin);

    const position = (index < 0 ? NYI_NUMBER : index + 1).toString();
    url.searchParams.set("index", "product");
    url.searchParams.set("queryId", queryId);
    url.searchParams.set("position", position);

    hrefWithQueryParams = url.toString().replace(window.location.origin, "");
  }

  return (
    <a
      ref={setRefs}
      onClick={handleClick}
      href={queryId ? hrefWithQueryParams : href}
      className={cn(
        "no-underline hover:no-underline h-[332px] w-full max-w-[330px] mx-auto md:mx-0",
        props.className,
      )}
    >
      <ProductCardSSRContent {...props} />
    </a>
  );
}

export default function ProductCard(props: ProductCardProps) {
  return (
    <ClientOnly>
      <ClientSideProductCard {...props} />
    </ClientOnly>
  );
}
